.footer {
  padding-top: 30px;
  background: linear-gradient(0.25turn, #00081cef, #1a2133f0, #262d3ef6);
}

.containerFooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "empresa navegação parceiros";
}

.empresa {
  grid-area: empresa;
}

.redesSociais {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.redesSociais svg {
  height: 32px;
  width: 32px;
  color: #b8b8b8;
}

.redesSociais svg:hover {
  color: #da9653;
}

.navegação {
  grid-area: navegação;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navegação ul li a {
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #bdbdbd;
  text-decoration: none;
}

.navegação ul li a:hover {
  color: #da9653;
}

.navegação ul h4 {
  text-align: center;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #e07a13;
  padding-bottom: 30px;
}

.navegação ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.parceiros {
  grid-area: parceiros;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
}

.parceiros ul li a {
  text-decoration: none;
  color: #cbcbcb;
}

.parceiros ul li a:hover {
  color: #da9653;
}

.parceiros ul {
  list-style: none;
}

.parceiros ul h4 {
  text-align: center;
  color: #e07a13;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  padding-bottom: 30px;
}

/*Tablets*/
/*não é necessario mudança

@media screen and (max-width: 1024px) {
}

*/

/*Celulares*/
@media screen and (max-width: 470px) {
  .containerFooter {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "empresa";
  }

  .empresa .redesSociais {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .redesSociais a img {
    margin-left: 0px;
    padding: 0px;
  }

  .navegação {
    display: none;
  }

  .parceiros {
    display: none;
  }

  .logo-empresa {
    display: none;
  }
}

.segurança {
  background: linear-gradient(0.25turn, #00081c, #1a2133, #262d3e);
  padding-top: 70px;
}

.containerBranco2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  background-color: #fffffff2;
}

.imagem2 {
  grid-area: imagem;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-color: #00000025;
}

.imagem2 img {
  padding-left: 14px;
  padding-top: 12px;
}

.container2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "imagem titulo"
    "paragrafo paragrafo";
  background-color: #ffffff00;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  border: #262d3e 3px solid;
  border-radius: 20px;
  height: 180px;
}

.container2:hover {
  background-color: rgba(223, 223, 223, 0);
  border: #262d3e 3px solid;
  box-shadow: 10px 6px 5px 5px rgba(0, 0, 0, 0.1);
}

.container2 h3 {
  grid-area: titulo;
  color: #262d3e;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding-right: 30px;
  text-align: center;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
}

.paragrafoContainer2 {
  grid-area: paragrafo;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.container2 p {
  text-align: center;
  font-size: 22px;
  color: #404040;
}

/*Tablets*/
@media screen and (max-width: 1024px) {
  .containerBranco2 {
    overflow-x: scroll;
  }

  .container2 h3 {
    font-size: 25px;
  }

  .container2 p {
    font-size: 18px;
  }
}

/*Celulares*/
@media screen and (max-width: 470px) {
  .containerBranco2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    overflow-x: scroll;
  }

  .imagem2 {
    height: 50px;
    width: 50px;
    margin-left: 20px;
  }

  .imagem2 img {
    padding-left: 10px;
    padding-top: 10px;
  }

  .container2 {
    height: 150px;
    width: 250px;
  }

  .container2:hover {
    background-color: rgba(192, 192, 192, 0);
    border: rgb(0, 0, 0) 2px solid;
  }

  .container2 h3 {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    color: #262d3e;
  }

  .paragrafoContainer2 {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .container2 p {
    font-size: 15px;
  }
}

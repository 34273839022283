.btn-zap a img {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  z-index: 1000;
}

/*Celulares*/
@media screen and (max-width: 470px) {
  .btn-zap a img {
    width: 50px;
    height: 50px;
  }
}

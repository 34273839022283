.howWork {
  background: linear-gradient(0.25turn, #00081c, #1a2133, #262d3e);
  padding-top: 70px;
}

.containerBranco {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  background-color: #fffffff2;
}

.imagem {
  height: 60px;
  width: 60px;
  margin-top: 30px;
  margin-left: 50px;
  margin-bottom: 15px;
  border-radius: 50px;
  background-color: #00000025;
}

.imagem img {
  padding-left: 14px;
  padding-top: 12px;
}

.container {
  background-color: #ffffff00;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  border: black 2px solid;
  border-radius: 20px;
}

.container:hover {
  background-color: rgb(223, 223, 223);
  border: rgb(0, 0, 0) 2px solid;
}

.container h3 {
  text-align: center;
  padding: 20px 0;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
}

.paragrafoContainer {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 28px;
}

.container p {
  text-align: center;
  font-size: 22px;
  color: #404040;
}

/*Tablets*/
@media screen and (max-width: 1024px) {
  .containerBranco {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .container h3 {
    font-size: 25px;
  }

  .container p {
    font-size: 18px;
  }
}

/*Celulares*/
@media screen and (max-width: 470px) {
  .containerBranco {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    overflow-x: scroll;
  }

  .imagem {
    height: 50px;
    width: 50px;
    margin-left: 20px;
  }

  .imagem img {
    padding-left: 10px;
    padding-top: 10px;
  }

  .container {
    height: 350px;
    width: 250px;
  }

  .container:hover {
    background-color: rgba(192, 192, 192, 0);
    border: rgb(0, 0, 0) 2px solid;
  }

  .container h3 {
    font-size: 20px;
  }

  .paragrafoContainer {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .container p {
    font-size: 12px;
  }
}

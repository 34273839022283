.about {
  background: linear-gradient(0.25turn, #00081c, #1a2133, #262d3e);
}

.about h1 {
  color: #ffffff;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 48px;
}

.paragrafo {
  background-color: #ffffff0f;
  margin-left: 80px;
  margin-right: 80px;
  border-radius: 70px;
}

.paragrafo p {
  color: #ffffff;
  font-family: "Kameron", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  padding: 50px;
}

/*Tablets*/
@media screen and (max-width: 1024px) {
  .about h1 {
    font-size: 32px;
  }

  .paragrafo {
    margin-left: 40px;
    margin-right: 40px;
  }

  .paragrafo p {
    font-size: 20px;
  }
}

/*Celulares*/
@media screen and (max-width: 470px) {
  .about h1 {
    font-size: 25px;
  }

  .paragrafo {
    margin-left: 15px;
    margin-right: 15px;
  }

  .paragrafo p {
    text-align: center;
    font-size: 12px;
    padding: 35px;
  }
}
